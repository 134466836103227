<section class="cover unpad--bottom switchable switchable--switch bg--secondary text-center-xs">
<section class="text-center">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>Kiss Dirty Laundry Goodbye</h2>
                <p class="lead">
                     Reclaim your free time by letting us handle the chore of washing and folding your laundry.
                </p>
                    <p class="lead" class="center">
                    No per pound fees! Service is easy to use! Reliable weekly service!
                </p>
                 <a class="btn btn--primary type--uppercase" [routerLink]="['/signup']">
                    <span class="btn__text">
                        Sign&nbsp;Up
                    </span>
                </a>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>
    <!--end of container-->
</section>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="feature feature-5 boxed boxed--lg boxed--border">
                    <i class="icon icon-Full-Bag icon--lg"></i>
                    <div class="feature__body">
                        <h5>Large Loads</h5>
                        <p>
                            We charge by the bin, not by the pound, and the bins we use are large enough for all of your laundry. Even bedding!
                        </p>
                        <a href="faq?subject=binsize">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="feature feature-5 boxed boxed--lg boxed--border">
                    <i class="icon icon-Wonder-Woman icon--lg"></i>
                    <div class="feature__body">
                        <h5>Professional Washers</h5>
                        <p>
                          FairMeadow's staff are made up of professionals with years of experience in home service management and hospitality.
                        </p>
                        <a href="faq?subject=staff">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="feature feature-5 boxed boxed--lg boxed--border">
                    <i class="icon icon-Clock icon--lg"></i>
                    <div class="feature__body">
                        <h5>Next Day Delivery</h5>
                        <p>
                            We pick up your laundry and deliver it the very next day, so you're never without the clothes you need to look your best.
                        </p>
                        <a href="faq?subject=delivery">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="feature feature-5 boxed boxed--lg boxed--border">
                    <i class="icon icon-Biotech icon--lg"></i>
                    <div class="feature__body">
                        <h5>Eco-Friendly and Hypo-allergenic</h5>
                        <p>
                            FairMeadow uses only eco-friendly, non-scented, hypo-allergenic detergents. We get great results without harming you or the environment.
                        </p>
                        <a href="faq?subject=eco">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>

<section class="feature-large bg--secondary">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-6">
                <img alt="Image" class="border--round box-shadow-wide" src="/assets/inner-6.jpg" />
            </div>
            <div class="col"></div>
            <div class="col-md-5 col-lg-5">
                <h3>Quality laundry service for less than you spend on coffee each week.<sup>*</sup></h3>
                <p>
                    Our laundry service is up to 65% less than competitive wash and fold services. By using proprietary software for route and load 
                    optimization we are able to perform the same service for less, freeing you to do the things you really want to do with your time.
                </p>
                <p class="type--fine-print">
                    <sup>*</sup>Based on the coffee spending habits of the average American.
                </p>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>

<section class="text-center">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>A trusted partner to the people you know.</h2>
                <p class="lead">
                    We work hard to earn the trust and loyalty of your neighbors, just ask them.
                </p>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>

<section class=" ">
    <div class="container">
        <div class="row">
            <div class="col"></div>
            <div class="col-lg-3">
                <div>
                    <img class="testimonial" alt="Image" src="/assets/herzog.jpg" />
                </div>
                <div>
                    <span>&ldquo;FairMeadow is super convenient. My clothes are returned the next day clean, fresh and nicely folded. I really like that I have one less thing to do because of this service!&rdquo;
                    </span>
                    <h5 class="testimonial">&mdash;Julie Herzog</h5>
                    <span>Stapleton, Denver</span>
                </div>       
            </div>
            <div class="col"></div>
            <div class="col-lg-3">
                <div>
                    <img class="testimonial" alt="Image" src="/assets/parker.jpg" />
                </div>
                <div>
                    <span>&ldquo;FairMeadow laundry service is like a dream come true. My Sundays are now spent doing what I want to do, and are no longer consumed with getting laundry ready for the week. This is a great, affordable service that is run by a husband and wife team who live in Stapleton. I am such a fan and will recommend FairMeadow every chance I get. Thank you!&rdquo;
                    </span>
                    <h5 class="testimonial">&mdash;Dawn Parker</h5>
                    <span>Stapleton, Denver</span>
                </div>
            </div>
            <div class="col"></div>
            <div class="col-lg-3">
                <div>
                    <img class="testimonial" alt="Image" src="/assets/olson.png" />
                </div>
                <div>
                    <span>&ldquo;We love the service. It's way quicker than I expected! When I was first told about it I was worried that we would be without 'needed' clothing when we needed it the most. The clothes were neatly folded so no need for ironing. My only complaint is that it made me so lazy!&rdquo;
                    </span>
                    <h5 class="testimonial">&mdash;Lisa Olson</h5>
                    <span>Stapleton, Denver</span>
                </div>
            </div>      
            <div class="col"></div>       
        </div>
    </div>
</section>

<!-- <footer class="text-center-xs space--xs bg--dark ">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <ul class="list-inline">
                    <li>
                        <a href="#">
                            <span class="h6 type--uppercase">About</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span class="h6 type--uppercase">Careers</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span class="h6 type--uppercase">Support</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-md-5 text-right text-center-xs">
                <a class="type--fine-print" href="#">support@fairmeadow.app</a>
            </div>
        </div>
        end of row
    </div>
    end of container
</footer> -->