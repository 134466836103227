import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { StripeService } from '../../services/stripe.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import * as moment from 'moment-timezone';
import { UserService } from '../../services/user.service';
import { ConfigService } from '../../services/config.service';
export class BasicSettingsComponent {
    constructor(afa, aff, afs, cd, stripe, userService, config, router) {
        this.afa = afa;
        this.aff = aff;
        this.afs = afs;
        this.cd = cd;
        this.stripe = stripe;
        this.userService = userService;
        this.config = config;
        this.router = router;
        this.id = "";
        this.customer = {};
        this.cancelRequest = false;
        this.cardHandler = this.onChange.bind(this);
        this.errors = [];
        this.paymentErrors = [];
        this.cancelErrors = [];
        this.success = false;
        this.paymentSuccess = false;
        this.affiliates = [];
        this.orders = [];
    }
    ngOnInit() {
        this.afs.collection('orders', (ref) => ref.where("customerId", "==", this.afa.auth.currentUser.uid).where("date", ">", new Date())).snapshotChanges().subscribe((snapshot) => {
            this.orders = snapshot.map((d) => { return Object.assign({ id: d.payload.doc.id, moment: moment(d.payload.doc.data()['date'].seconds * 1000) }, d.payload.doc.data()); });
        });
        this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).valueChanges().subscribe((customer) => {
            if (!customer)
                return;
            this.id = this.afa.auth.currentUser.uid;
            if (customer.status == "abandoned" || customer.status == "hidden") {
                if (this.userService.isInServiceArea(customer)) {
                    this.router.navigate(['/signup/step2']);
                }
                else {
                    this.router.navigate(['signup', 'coming-soon']);
                }
            }
            if (!customer.affiliate) {
                customer.affiliate = null;
            }
            if (!customer.scent) {
                customer.scent = "None";
            }
            if (!customer.waterTemp) {
                customer.waterTemp = "Cold";
            }
            this.customer = customer;
        });
        this.afs.collection('affiliates').snapshotChanges().subscribe((snapshot) => {
            this.affiliates = snapshot.map((d) => { return Object.assign({ id: d.payload.doc.id }, d.payload.doc.data()); });
        });
    }
    ngAfterViewInit() {
        this.card = this.stripe.elements.create('card', { style: {
                base: {
                    lineHeight: '21px'
                }
            }
        });
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    }
    ngOnDestroy() {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    }
    onChange({ error }) {
        if (error) {
            this.paymentErrors.push(error.message);
        }
        else {
            this.paymentErrors = [];
        }
        this.cd.detectChanges();
    }
    updateCard() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.paymentErrors = [];
            this.paymentSuccess = false;
            const { token, error } = yield this.stripe.client.createToken(this.card);
            if (error) {
                this.paymentErrors.push(error.message);
            }
            else {
                yield this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({ stripeToken: token });
                yield this.aff.functions.httpsCallable('updateCustomerCardFromToken')();
                this.paymentSuccess = true;
            }
        });
    }
    saveChanges() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.success = false;
            if (this.validate()) {
                const fields = {
                    firstName: this.customer.firstName,
                    lastName: this.customer.lastName,
                    instructions: this.customer.instructions,
                    phone: this.customer.phone,
                    scent: this.customer.scent,
                    waterTemp: this.customer.waterTemp,
                    reminders: !!this.customer.reminders,
                    notifications: !!this.customer.notifications,
                    softener: !!this.customer.softener,
                    affiliate: this.customer.affiliate,
                };
                const result = yield this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update(fields);
                this.success = true;
            }
        });
    }
    requestCancellation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.cancelRequest) {
                const cancelDate = new Date();
                this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({ cancelRequested: true, cancelDate });
            }
            else {
                this.cancelErrors.push("You must confirm you want to cancel service using the provided checkbox.");
            }
        });
    }
    skipService() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
                skipCount: 1,
            });
        });
    }
    unskipService() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
                skipCount: 0,
            });
        });
    }
    pauseService() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
                paused: true,
                pausedAt: new Date()
            });
        });
    }
    unpauseService() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
                paused: false,
                unpausedAt: new Date()
            });
        });
    }
    canSkip() {
        const date = moment().tz("America/Denver");
        if (date.day() == this.customer.laundryday)
            return false;
        return true;
    }
    uncancel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
                cancelRequested: firebase.firestore.FieldValue.delete(),
                cancelDate: firebase.firestore.FieldValue.delete()
            });
        });
    }
    validate() {
        return true;
    }
}
