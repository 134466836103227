import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UserService } from '../../services/user.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.css']
})
export class EditOrderComponent implements OnInit {

  public pickupDays: Array<any>;
  public pickupDay = 0;

  public binArray: Array<any> = [];
  public order:any = {};
  public orderId: string = null;
  public canChange = null; // null triggers loading message
  public customer = null;

  constructor(
    private afa: AngularFireAuth, 
    private afs: AngularFirestore, 
    private aff: AngularFireFunctions, 
    private route: ActivatedRoute,
    private config: ConfigService,
    private userService: UserService,
    private router: Router) { }

  ngOnInit() {
    this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).valueChanges().subscribe((customer: any) => {
      if(!customer) return;
      if(customer.status == "abandoned" || customer.status == "hidden") {
        if(this.userService.isInServiceArea(customer)) {
          this.router.navigate(['/signup/step2']);
        } else {
          this.router.navigate(['signup','coming-soon']);
        }
      }
      if(!customer.scent) {
        customer.scent = "None";
      } 
      if(!customer.waterTemp) {
        customer.waterTemp = "Cold";
      }
      this.customer = customer;
      this.binArray = Array.from(new Array(customer.bincount),(val,index)=>index+1);
    });
    this.route.params.subscribe((params) => {
      this.afs.collection('orders').doc(params['id']).valueChanges().subscribe((order: any) => {
        const editCutoff = new Date();
        editCutoff.setHours(20);
        editCutoff.setMinutes(0);
        editCutoff.setSeconds(0);
        this.order = order;
        this.orderId = params['id'];
        
        if(order.date.toDate() > editCutoff) {
          this.canChange = true;
          this.pickupDays = this.getOnDemandDays();
        } else {
          this.canChange = false;
          this.pickupDays = [
            {
              date: order.date.toDate(),
              label: moment(order.date.toDate()).format("dddd, MMMM Do")
            }
          ];
          this.pickupDay = 0;
        }
      });
    });
  }

  public getOnDemandDays(): Array<any> {
    const today = moment().hour(20).minute(0).second(0); // today at 8pm
    const days: Array<any> = [];
    const nextDay = moment(today);

    while(days.length < 5) {
      nextDay.add(1, 'days');
      while(nextDay.day() < 1 || nextDay.day() > 4) {
        nextDay.add(1, 'days');
      }

      days.push({
        date: nextDay.toDate(),
        label: nextDay.format("dddd, MMMM Do")
      });

      if(nextDay.toDate().toDateString() == this.order.date.toDate().toDateString()) {
        this.pickupDay = days.length - 1;
        console.log(`setting pickupday to ${this.pickupDay}`);
      }
    }
    
    return days;
  }

  public async saveChanges() {
    await this.afs.collection('orders').doc(this.orderId).update({
      ...this.order,
      date: this.pickupDays[this.pickupDay].date
    });
    this.router.navigate(['/settings']);
  }

}
