
<section>
  <div class="container">
    <div class="row">
      <div class="col"></div>
      <div class="col-md-6 col-xs-12">
        <div class="card">
          <div class="card-header text-center">
              <h1>Coming Soon</h1>    
          </div>
          <div class="card-body">
            <h4>
              Thank you for your interest in our service! Unfortunately we don't serve your neighborhood yet. 
              We are working hard every day to increase our service area and will let you know as soon as we
              are able to sign you up!
            </h4>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
</section>
