
<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ikrZPpBa6PA" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <div class="col-12">
      <h1>Rise Acupucture</h1>
      <p>
          This last week I underwent three kinds of Chinese Medicine Therapies right here in Denver, specifically in the Stapleton Neighborhood: 1) Acupuncture and Moxibustion to Boost Immunity 2) Facial Acupuncture and 3) Cupping Therapy.  Press Play to see how these amazing treatments went.  What I can tell you from my own experience is that I will always choose cupping therapy to relieve back pain!<br/>
          <br/>
          CONTACT RENATA » <a href="https://www.riseholisticmedicine.com">https://www.riseholisticmedicine.com</a><br/>
          CONNECT WITH RENATA » <a href="https://www.facebook.com/riseholisticmedicine/">https://www.facebook.com/riseholistic...</a><br/>
          <br/>
      </p>
    </div>
  </div>
</div>