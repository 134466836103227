
<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/j3TPqgDxi64" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <div class="col-12">
      <h1>L-Style Bar</h1>
      <p>
        This last week I experienced 1) a Spray Tan 2) a Blowout 3) a Custom Blend Foundation and 4) a Makeup Transformation from Denver's very own L Style Bar.  Press Play to see how these amazing treatments went.  What I can tell you from my own experience is that a blowout is THE BEST pampering experience of all time! 
        <br/>
          CONTACT L STYLE BAR  » <a href="http://lstylebar.com">http://lstylebar.com</a><br/>
          CONNECT L STYLE BAR » <a href="https://www.facebook.com/lstylebar">https://www.facebook.com/lstylebar...</a><br/>
          LET'S TALK ABOUT THE VIDEO » <a href="https://www.instagram.com/lstylebar">https://www.instagram.com/lstylebar</a><br/>
          <br/>
          PREVIOUS VIDEO » <a href="https://fairmeadow.app/videos/rise-acupucture">https://fairmeadow.app/videos/rise-accupuncture</a>
      </p>
    </div>
  </div>
</div>