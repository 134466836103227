<section>
    <div class="container">
        <div class="row">
            <div class="col"></div>
            <div class="col-md-6 col-xs-12">
                
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title text-center">Sign In</h3>
                        <form class="form-signin">
                        <div class="form-group">
                            <label for="inputEmail" class="sr-only">Email address</label>
                            <input type="email" name="email" id="inputEmail" class="form-control" placeholder="Email address" required autofocus [(ngModel)]="email">    
                            <label for="inputPassword" class="sr-only">Password</label>
                            <input type="password" name="password" id="inputPassword" class="form-control" placeholder="Password" required [(ngModel)]="password">
                        </div>
                        <div class="form-group">
                            <button class="btn btn--primary type--uppercase" type="submit" (click)="login()">Sign in</button>
                        </div>
                        
                        </form>
                        <div  *ngFor="let error of errors">
                            <div class="alert alert-danger" role="alert">
                                {{error}}
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="text-center">
                            <p> 
                                <a [routerLink]="['/signup']">Don't have an account yet? Sign Up!</a>
                                <br>
                                <a [routerLink]="['/forgot-password']">Forgot Password?</a>
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="col"></div>
        </div>
    </div>
</section>