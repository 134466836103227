import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { StripeService } from '../../services/stripe.service';
import { UserService } from '../../services/user.service';
import { FacebookService } from '../../services/facebook.service';
export class Step2Component {
    constructor(afAuth, afDb, router, cd, userService, stripe, facebook) {
        this.afAuth = afAuth;
        this.afDb = afDb;
        this.router = router;
        this.cd = cd;
        this.userService = userService;
        this.stripe = stripe;
        this.facebook = facebook;
        this.cardHandler = this.onChange.bind(this);
        this.city = "Denver";
        this.state = "Colorado";
        this.instructions = "";
        this.serviceType = "basic";
        this.bincount = 1;
        this.referral = "";
        this.affiliate = null;
        this.affiliates = [];
    }
    ngOnInit() {
        this.referral = localStorage.getItem('referredBy');
        this.afDb.collection('customers').doc(this.afAuth.auth.currentUser.uid).valueChanges().subscribe((customer) => {
            if (!this.userService.isInServiceArea(customer)) {
                this.router.navigate(['signup', 'coming-soon']);
            }
        });
        this.afDb.collection('affiliates').snapshotChanges().subscribe((snapshot) => {
            this.affiliates = snapshot.map((d) => { return Object.assign({ id: d.payload.doc.id }, d.payload.doc.data()); });
        });
    }
    ngAfterViewInit() {
        this.card = this.stripe.elements.create('card', { style: {
                base: {
                    lineHeight: '21px'
                }
            }
        });
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    }
    ngOnDestroy() {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    }
    onChange({ error }) {
        if (error) {
            this.errors.push(error.message);
        }
        else {
            this.errors = [];
        }
        this.cd.detectChanges();
    }
    validate() {
        this.errors = [];
        if (this.errors.length > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    saveContact() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.validate()) {
                const { token, error } = yield this.stripe.client.createToken(this.card);
                if (error) {
                    this.errors.push(error);
                }
                else {
                    if (token.card.funding == "prepaid") {
                        // don't accept prepaid cards
                        this.errors.push("We don't support prepaid cards. Please use a debit or credit card.");
                    }
                    else {
                        this.afDb.collection('customers').doc(this.afAuth.auth.currentUser.uid).update({
                            serviceType: this.serviceType,
                            bincount: +this.bincount,
                            affiliate: this.affiliate,
                            city: this.city,
                            state: this.state,
                            instructions: this.instructions,
                            referredBy: this.referral,
                            stripeToken: token,
                            reminders: true,
                            status: "applicant",
                        }).then((result) => {
                            this.facebook.trackPurchase();
                            window.gtag('event', 'sign_up', { method: 'E-Mail' });
                            window.gtag('event', 'conversion', { 'send_to': 'AW-799654185/s4Q9COf6wJEBEKmCp_0C' });
                            this.router.navigate(['signup', 'thankyou']);
                        });
                    }
                }
            }
        });
    }
}
