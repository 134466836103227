import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { isString } from 'util';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {

  constructor() { }

  public getScents(): Array<any> {
    return Object.keys(environment.scents).map((k) => { 
      return { key: k, value: k, children: Object.keys(environment.scents[k]).map((ck) => {
        return { key: ck, value: environment.scents[k][ck] };
      })}; 
    });
  }
}
