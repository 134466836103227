<section *ngIf="customer.cancelRequested">
    <div class="container">
        <div class="row" >
            <div class="col-md-12">
                
                <div class="alert alert-warning fade show" role="alert">
                        <h2>IMPORTANT!</h2>
                        <hr>
                        <p>You have requested that your service be cancelled, but there is one more step before your cancellation is complete. Please leave all of your bins in your designated pickup place for final pickup. Please make sure that they are empty and clean.
                            Once we pick up your bins, your service cancellation will be complete.
                        </p>
                        <div class="text-right">
                            <a id="uncancel" class="btn btn--primary type--uppercase" (click)="uncancel()"><span class="btn__text">Don't cancel my account!</span></a>    
                        </div>
                </div>
                
            </div> 
        </div>
    </div>
</section>
<section  *ngIf="!customer.cancelRequested">
    <div class="container">
        <div class="row" *ngIf="customer.serviceType == 'premium' || customer.serviceType == 'basic' || customer.serviceType == 'beta'">
            <div class="col-md-12">
                <div class="boxed boxed--lg boxed--border">
                    <h2>Referral Program</h2>
                    <hr>
                    <div>
                        <p>Want some free service? Share the link below with your friends and get 2 free weeks of service for everyone that signs up! They'll also get 50% off of their first month, so everyone wins! Your custom link is below:</p>
                        <h4>https://fairmeadow.app/signup?ref={{ id }}</h4>
                    </div>
                </div>
                <div class="boxed boxed--lg boxed--border">
                    <h2>Skip Service</h2>
                    <hr>
                    <div *ngIf="!customer.skipCount && canSkip()">
                            <form>
                                <div class="form-group">
                                    <p>On vacation? Not enough laundry this week? Not able to get your bin out? No problem! Just click skip and we will credit your account for the week and try again next week.</p>
                                    <div class="form-group text-right">
                                            <a id="skipService" class="btn btn--primary type--uppercase" (click)="skipService()"><span class="btn__text">Skip Service</span></a>    
                                    </div>
                                </div>
                            </form>
                    </div>
                    <div *ngIf="customer.skipCount && canSkip()">
                            <form>
                                    <div class="form-group">
                                        <p>Your service is set to be skipped this coming week. If you've changed your mind you can cancel your request.</p>
                                        <div class="form-group text-right">
                                                <a id="unskipService" class="btn btn--primary type--uppercase" (click)="unskipService()"><span class="btn__text">Cancel Skip Request</span></a>    
                                        </div>
                                    </div>
                            </form>
                    </div>
                    <div *ngIf="!canSkip()">
                        <form>
                            <div class="form-group">
                                <p>It's currently too close to your delivery or pick-up window to skip your service, or cancel your skip request.</p>
                            </div>
                        </form>
                    </div>
                    <br/><br/>
                    <h2>Pause Service</h2>
                    <hr>
                    <div *ngIf="!customer.paused && canSkip()">
                            <form>
                                <div class="form-group">
                                    <p>Need to skip multiple weeks, or even months, of service? Going away for the summer? Pause your service while you're away!</p>
                                    <div class="form-group text-right">
                                            <a id="skipService" class="btn btn--primary type--uppercase" (click)="pauseService()"><span class="btn__text">Pause Service</span></a>    
                                    </div>
                                </div>
                            </form>
                    </div>
                    <div *ngIf="customer.paused && canSkip()">
                            <form>
                                    <div class="form-group">
                                        <p>Your service currently paused. You can unpause your service whenever you want to start it again.</p>
                                        <div class="form-group text-right">
                                                <a id="unskipService" class="btn btn--primary type--uppercase" (click)="unpauseService()"><span class="btn__text">Un-Pause Service</span></a>    
                                        </div>
                                    </div>
                            </form>
                    </div>
                    <div *ngIf="!canSkip()">
                        <form>
                            <div class="form-group">
                                <p>It's currently too close to your pick-up window to pause or unpause your service.</p>
                            </div>
                        </form>
                    </div>
                    <br/><br/>
                    <div *ngIf="customer.serviceType != 'basic'">
                            <h2>Laundry Settings</h2>
                            <hr>
                            <form>
                                <div class="form-group">
                                    <label for="card-info">Water Temperature</label>
                                    <div class="input-select">
                                        <select name="waterTemp" [(ngModel)]="customer.waterTemp">
                                          <option value="Cold">Cold (Recommended)</option>
                                          <option value="Warm">Warm</option>
                                          <option value="Hot">Hot</option>
                                        </select>
                                      </div>
                                </div>
                                <div class="form-group">
                                    <label for="card-info">Laundry Scent</label>
                                    <div class="input-select">
                                        <select name="scent" [(ngModel)]="customer.scent">
                                            <option value="None">None</option>
                                            <optgroup *ngFor="let group of config.getScents()" [label]="group.value">
                                                <option *ngFor="let option of group.children" [value]="option.key">{{option.value}}</option>
                                            </optgroup>
                                        </select>
                                      </div>
                                </div>
                                <div class="form-group form-check">
                                    <input type="checkbox" name="softener" class="form-check-input" id="softener" [(ngModel)]="customer.softener">
                                    <label class="form-check-label" for="softener">Add Eco-Friendly Fabric Softener (Made by FairMeadow!)</label>
                                </div>
                            </form>
                            <br/><br/>
                    </div>
                    <h2>Pick-Up/Delivery</h2>
                    <hr>
                    <form>
                        <div class="form-group">
                          <div class="form-row">
                            <div class="col">
                                <label for="firstName">First Name</label>
                                <input type="text" name="first" class="form-control" id="firstName" placeholder="First Name" [(ngModel)]="customer.firstName">
                            </div>
                            <div class="col">
                                <label for="lastName">Last Name</label>
                                <input type="text" name="last" class="form-control" id="lastName" placeholder="Last Name" [(ngModel)]="customer.lastName">
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                            <label for="phone">Phone Number</label>
                            <input type="text" class="form-control" id="phone" placeholder="Phone Number" name="phone" [(ngModel)]="customer.phone">
                        </div>
                        
                        <div class="form-group">
                                <label for="instructions">Pickup/Drop-off Instructions</label>
                                <textarea name="instructions" [(ngModel)]="customer.instructions" class="form-control" id="instructions" rows="4" placeholder="Example: Please enter through the garage via the alleyway. the code is 1234. Leave the bins by the bikerack"></textarea>
                        </div>
                        <div class="form-group form-check">
                            <input type="checkbox" name="reminders" class="form-check-input" id="reminders" [(ngModel)]="customer.reminders">
                            <label class="form-check-label" for="reminders">Remind me the night before my laundry is picked up (reminders are sent around 6pm)</label>
                        </div>
                        <div class="form-group form-check">
                            <input type="checkbox" name="notifications" class="form-check-input" id="notifications" [(ngModel)]="customer.notifications">
                            <label class="form-check-label" for="notifications">Notify me when pickup and delivery occur. (beta)</label>
                        </div>
                        
                        <div class="form-group text-right">
                            <a id="payment" class="btn btn--primary type--uppercase" (click)="saveChanges()"><span class="btn__text">Save Changes</span></a>    
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="customer.serviceType == 'ondemand'">
            <div class="col-md-12">
                <div class="boxed boxed--lg boxed--border">
                    <h2>On-Demand Service</h2>
                    <hr>
                    <div *ngFor="let order of orders">
                        <h5>
                                Pickup scheduled for {{ orders[0].bincount }} bins on {{ orders[0].moment.format('dddd, MMMM Do YYYY') }}.
                        </h5>
                        
                        <br/>
                        <form>
                            <div class="form-group text-left">
                                <a id="payment" class="btn btn--primary type--uppercase" [routerLink]="['/orders',order.id]"><span class="btn__text">Change</span></a>    
                            </div>
                        </form>
                    </div>
                    <form *ngIf="orders.length == 0">
                        <div class="form-group text-left">
                            <a id="payment" class="btn btn--primary type--uppercase" [routerLink]="['/settings/schedule-service']"><span class="btn__text">Schedule Service</span></a>    
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="boxed boxed--lg boxed--border">
                    <h2>Donation Settings</h2>
                    <hr>
                    <form>
                        <div class="form-group">
                            <label for="card-info">Donate 5% to the Following School</label>
                            
                            <div class="input-select">
                                <select id="affiliate" name="affiliate" [(ngModel)]="customer.affiliate">
                                    <option [ngValue]="null">None</option>
                                    <option *ngFor="let affiliate of affiliates" [value]="affiliate.id">{{affiliate.display_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="success">
                            <div class="alert alert-success" role="alert">
                                Changes saved successfully.
                            </div>
                        </div>
                        <div class="form-group" *ngFor="let error of errors">
                            <div class="alert alert-danger" role="alert">
                                {{error}}
                            </div>
                        </div>
                        <div class="form-group text-right">
                            <a id="payment" class="btn btn--primary type--uppercase" (click)="saveDonation()"><span class="btn__text">Save Changes</span></a>    
                        </div>
                    </form>
                </div>
                <!--end of pricing-->
            </div>
            
        </div>
        <!--end of row-->

        <div class="row">
            <div class="col-md-12">
                <div class="boxed boxed--lg boxed--border">
                    <h2>Payment</h2>
                    <hr>
                    <form>
                        <div class="form-group">
                            <label for="card-info">Current Card Information</label>
                            <div *ngIf="customer.stripeToken"><strong>{{ customer.stripeToken.card.brand }} ending in {{ customer.stripeToken.card.last4 }}. Expires: {{ customer.stripeToken.card.exp_month }}/{{ customer.stripeToken.card.exp_year }}</strong></div>    
                        </div>
                        <div class="form-group">
                            <label for="card-info">Update Card</label>
                            <div id="card-info" class="form-control" #cardInfo></div>    
                        </div>
                        <div class="form-group" *ngIf="paymentSuccess">
                            <div class="alert alert-success" role="alert">
                                Card updated successfully.
                            </div>
                        </div>
                        <div class="form-group" *ngFor="let error of paymentErrors">
                            <div class="alert alert-danger" role="alert">
                                {{error}}
                            </div>
                        </div>
                        <div class="form-group text-right">
                            <a id="payment" class="btn btn--primary type--uppercase" (click)="updateCard()"><span class="btn__text">Update Card</span></a>    
                        </div>
                    </form>
                </div>
                <!--end of pricing-->
            </div> 
        </div>
       
        <div class="row">
                <div class="col-md-12">
                    <div class="boxed boxed--lg boxed--border">
                        <h2>Service Cancellation</h2>
                        <hr>
                        <p>
                            Once your request is processed you will be given information on how to return your laundry bins. Once your laundry bins are returned your service will be 
                            officially cancelled and your credit card will no longer be billed.
                        </p>
                        <form>
                            <div class="form-group">
                                <div class="form-group form-check">
                                    <input type="checkbox" name="cancelRequest" class="form-check-input" id="cancelRequest" [(ngModel)]="cancelRequest">
                                    <label class="form-check-label" for="cancelRequest">Yes, please cancel my service.</label>
                                </div>
                            </div>
                            <div class="form-group" *ngFor="let error of errors">
                                <div class="alert alert-danger" role="alert">
                                    {{error}}
                                </div>
                            </div>
                            <div class="form-group" *ngFor="let error of cancelErrors">
                                <div class="alert alert-danger" role="alert">
                                    {{error}}
                                </div>
                            </div>
                            <div class="form-group text-right">
                                <a id="payment" class="btn btn--primary type--uppercase" (click)="requestCancellation()"><span class="btn__text">Request Cancellation</span></a>    
                            </div>
                        </form>
                    </div>
                    <!--end of pricing-->
                </div> 
            </div>
        <!--end of row-->
</div>
    <!--end of container-->
</section>