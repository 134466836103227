import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  constructor(
    private router: Router, 
    private database: AngularFirestore,
    private route: ActivatedRoute,
    private auth: AngularFireAuth) {

    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        if(!event.urlAfterRedirects.startsWith('/admin')) {
          (<any>window).gtag('config', 'UA-117928097-1', {
            'page_path': event.urlAfterRedirects
          });  
        }
      }
    });

    this.auth.authState.subscribe(async (user) => {
      if(user === null) {
        console.log('user is null');
       
      } else {
        this.database.collection('customers').doc(user.uid).valueChanges().subscribe((customer: any) => {
          if(customer) {
           
            (<any>window).gtag('event', 'login', { method : 'E-Mail' });
          }
        });
      }
    });
  }

  ngOnInit() {
    
  }
}