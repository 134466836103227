import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { StripeService } from '../../services/stripe.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import * as moment from 'moment-timezone'

import { UserService } from '../../services/user.service';
import { ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-basic-settings',
  templateUrl: './basic-settings.component.html',
  styleUrls: ['./basic-settings.component.css']
})
export class BasicSettingsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;

  public id: string = "";
  public customer: any = {};
  public cancelRequest: boolean = false;
  public card: any;
  public cardHandler = this.onChange.bind(this);
  public errors: Array<string> = [];
  public paymentErrors: Array<string> = [];
  public cancelErrors: Array<string> = [];
  public success: boolean = false;
  public paymentSuccess: boolean = false;
  public affiliates: Array<any> = [];
  public orders: Array<any> = [];
  
  constructor(
    private afa: AngularFireAuth, 
    private aff: AngularFireFunctions,
    private afs: AngularFirestore, 
    private cd: ChangeDetectorRef,
    private stripe: StripeService,
    private userService: UserService,
    private config: ConfigService,
    private router: Router) { }

  ngOnInit() {
    this.afs.collection('orders', (ref) => ref.where("customerId","==",this.afa.auth.currentUser.uid).where("date",">", new Date())).snapshotChanges().subscribe((snapshot) => {
      this.orders = snapshot.map((d) => { return { id: d.payload.doc.id, moment: moment(d.payload.doc.data()['date'].seconds * 1000), ...d.payload.doc.data() }});
    });

    this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).valueChanges().subscribe((customer: any) => {
      if(!customer) return;
      this.id = this.afa.auth.currentUser.uid;
      if(customer.status == "abandoned" || customer.status == "hidden") {
        if(this.userService.isInServiceArea(customer)) {
          this.router.navigate(['/signup/step2']);
        } else {
          this.router.navigate(['signup','coming-soon']);
        }
      }
      if(!customer.affiliate) {
        customer.affiliate = null;
      }
      if(!customer.scent) {
        customer.scent = "None";
      } 
      if(!customer.waterTemp) {
        customer.waterTemp = "Cold";
      }
      this.customer = customer;
    });
    this.afs.collection('affiliates').snapshotChanges().subscribe((snapshot) => {
      this.affiliates = snapshot.map((d) => { return { id: d.payload.doc.id, ...d.payload.doc.data() }});
    });
  }

  ngAfterViewInit() {
    this.card = this.stripe.elements.create('card', { style:
      {
        base: {
          lineHeight: '21px'
        }
      }
    });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if(error) {
      this.paymentErrors.push(error.message);
    } else {
      this.paymentErrors = [];
    }
    this.cd.detectChanges();
  }

  public async updateCard() {
    this.paymentErrors = [];
    this.paymentSuccess = false;
    const { token, error } = await this.stripe.client.createToken(this.card);
    if (error) {
      this.paymentErrors.push(error.message);
    } else {
      await this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({ stripeToken: token });
      await this.aff.functions.httpsCallable('updateCustomerCardFromToken')();
      this.paymentSuccess = true;
    }
  }

  public async saveChanges() {
    this.success = false;
    if(this.validate()) {
      const fields = {
        firstName: this.customer.firstName,
        lastName: this.customer.lastName,
        instructions: this.customer.instructions,
        phone: this.customer.phone,
        scent: this.customer.scent,
        waterTemp: this.customer.waterTemp,
        reminders: !!this.customer.reminders,
        notifications: !!this.customer.notifications,
        softener: !!this.customer.softener,
        affiliate: this.customer.affiliate,
      };
      const result = await this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update(fields);
      this.success = true;
    }
  }

  public async requestCancellation() {
    if(this.cancelRequest) {
      const cancelDate = new Date();
      this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({ cancelRequested: true, cancelDate });
    } else {
      this.cancelErrors.push("You must confirm you want to cancel service using the provided checkbox.");
    }
  }

  public async skipService() {
    this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
      skipCount: 1,
    });
  }

  public async unskipService() {
    this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
      skipCount: 0,
    });
  }

  public async pauseService() {
    this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
      paused: true,
      pausedAt: new Date()
    });
  }
  public async unpauseService() {
    this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
      paused: false,
      unpausedAt: new Date()
    });
  }

  public canSkip() {
    const date = moment().tz("America/Denver");
    if(date.day() == this.customer.laundryday) return false;
    return true;
  }

  public async uncancel() {
    this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({ 
      cancelRequested: firebase.firestore.FieldValue.delete(), 
      cancelDate: firebase.firestore.FieldValue.delete() 
    });
  }

  public validate() {
    return true;
  }
}
