
<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/viowoBo0irQ" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <div class="col-12">
      <h1>Dear Abby Tutors</h1>
      <p>
        Watch the video to see my family's first experience with my kindergartener at Denver Public School and why we engaged Abby, my daughter's reading tutor.  Looking back, I wish I had engaged Abby BEFORE kindergarten. I highly recommend her services, check her out at www.dearabbytutors.com
        <br/>
        <br/>
          CONTACT ABBY  » <a href="http://www.dearabbytutors.com">http://www.dearabbytutors.com</a><br/>
          <br/>
          PREVIOUS VIDEO » <a href="https://fairmeadow.app/videos/l-style-bar">https://fairmeadow.app/videos/l-style-bar</a>
      </p>
    </div>
  </div>
</div>