/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./website.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./website.component";
import * as i5 from "@angular/fire/auth";
import * as i6 from "../../services/admin-guard.service";
import * as i7 from "../../services/staff-guard.service";
var styles_WebsiteComponent = [i0.styles];
var RenderType_WebsiteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WebsiteComponent, data: {} });
export { RenderType_WebsiteComponent as RenderType_WebsiteComponent };
function View_WebsiteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵted(-1, null, ["Staff"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, "/staff"); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_WebsiteComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵted(-1, null, ["Admin"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, "/admin"); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_WebsiteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ul", [["class", "navbar-nav ml-auto"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebsiteComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebsiteComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵted(-1, null, ["Settings"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["class", "btn btn--sm btn--primary type--uppercase"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "btn__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign\u00A0Out"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isStaff; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isAdmin; _ck(_v, 4, 0, currVal_1); var currVal_4 = _ck(_v, 8, 0, "/settings"); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 7).target; var currVal_3 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_2, currVal_3); }); }
function View_WebsiteComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "ul", [["class", "navbar-nav ml-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, ["How It Works"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵted(-1, null, ["Pricing"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵted(-1, null, ["FAQ"])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(19, 1), (_l()(), i1.ɵted(-1, null, ["Contact Us"])), (_l()(), i1.ɵeld(21, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 4, "a", [["class", "btn btn--sm btn--primary type--uppercase"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(24, 1), (_l()(), i1.ɵeld(25, 0, null, null, 1, "span", [["class", "btn__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sign\u00A0In "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, "/howitworks"); _ck(_v, 3, 0, currVal_2); var currVal_5 = _ck(_v, 9, 0, "/pricing"); _ck(_v, 8, 0, currVal_5); var currVal_8 = _ck(_v, 14, 0, "/faq"); _ck(_v, 13, 0, currVal_8); var currVal_11 = _ck(_v, 19, 0, "/contactus"); _ck(_v, 18, 0, currVal_11); var currVal_14 = _ck(_v, 24, 0, "/auth/login"); _ck(_v, 23, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 8).target; var currVal_4 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 13).target; var currVal_7 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 18).target; var currVal_10 = i1.ɵnov(_v, 18).href; _ck(_v, 17, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵnov(_v, 23).target; var currVal_13 = i1.ɵnov(_v, 23).href; _ck(_v, 22, 0, currVal_12, currVal_13); }); }
export function View_WebsiteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "nav", [["class", "navbar navbar-expand navbar-light bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "navbar-brand"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/FairMeadowHorizontal.png"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_WebsiteComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, [["showUnauthenticated", 2]], null, 0, null, View_WebsiteComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, "/"); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.afAuth.user)); var currVal_4 = i1.ɵnov(_v, 11); _ck(_v, 7, 0, currVal_3, currVal_4); _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_WebsiteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_WebsiteComponent_0, RenderType_WebsiteComponent)), i1.ɵdid(1, 114688, null, 0, i4.WebsiteComponent, [i5.AngularFireAuth, i6.AdminGuard, i7.StaffGuard, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WebsiteComponentNgFactory = i1.ɵccf("app-layout", i4.WebsiteComponent, View_WebsiteComponent_Host_0, {}, {}, []);
export { WebsiteComponentNgFactory as WebsiteComponentNgFactory };
