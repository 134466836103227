<section>
    <div class="container">
        <div class="col-md-12" *ngIf="isProcessing == true">
            <div class="boxed boxed--lg boxed--border">
                <h2>Order Processing...</h2>
                <hr>
                <span>
                    Please do not use the back button or close your browser while your order is being processed. This usually takes less than a minute.
                </span>
            </div>
        </div>
        <div class="col-md-12" *ngIf="isProcessing == false">
            <div class="boxed boxed--lg boxed--border">
                <h2>Pick-Up/Delivery</h2>
                <hr>
                <form>
                    <div class="form-group">
                        <label for="card-info">Pickup Day</label>
                        <div class="input-select">
                          <select name="pickupDay" [(ngModel)]="pickupDay">
                            <option *ngFor="let day of pickupDays; let i = index;" [value]="i">{{day.label}}</option>
                          </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="card-info">Number of Bins</label>
                        <div class="input-select">
                        <select name="binCount" [(ngModel)]="binCount">
                            <option *ngFor="let i of binArray" [value]="i">{{i}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="phone">Phone Number</label>
                        <input type="text" class="form-control" id="phone" placeholder="Phone Number" name="phone" [(ngModel)]="customer.phone">
                    </div>
                    
                    <div class="form-group">
                            <label for="instructions">Pickup/Drop-off Instructions</label>
                            <textarea name="instructions" [(ngModel)]="customer.instructions" class="form-control" id="instructions" rows="4" placeholder="Example: Please enter through the garage via the alleyway. the code is 1234. Leave the bins by the bikerack"></textarea>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" name="reminders" class="form-check-input" id="reminders" [(ngModel)]="customer.reminders">
                        <label class="form-check-label" for="reminders">Remind me the night before my laundry is picked up (reminders are sent around 6pm)</label>
                    </div>
                    
                </form>
                <br/><br/>
                <h2>Laundry Settings</h2>
                <hr>
                <form>
                    
                    <div class="form-group">
                        <label for="card-info">Water Temperature</label>
                        <div class="input-select">
                            <select name="waterTemp" [(ngModel)]="customer.waterTemp">
                              <option value="Cold">Cold (Recommended)</option>
                              <option value="Warm">Warm</option>
                              <option value="Hot">Hot</option>
                            </select>
                          </div>
                    </div>
                    <div class="form-group">
                        <label for="card-info">Laundry Scent</label>
                        <div class="input-select">
                            <select name="scent" [(ngModel)]="customer.scent">
                                <option value="None">None</option>
                                <optgroup *ngFor="let group of config.getScents()" [label]="group.value">
                                    <option *ngFor="let option of group.children" [value]="option.key">{{option.value}}</option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" name="reminders" class="form-check-input" id="reminders" [(ngModel)]="customer.softener">
                        <label class="form-check-label" for="reminders">Add All Natural Eco-Friendly Fabric Softener (Made by FairMeadow!)</label>
                    </div>
                    <div class="form-group text-right">
                        <a id="payment" class="btn btn--primary type--uppercase" (click)="scheduleOrder()"><span class="btn__text">Place Order</span></a>    
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
