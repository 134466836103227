import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UserService } from '../../services/user.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-schedule-service',
  templateUrl: './schedule-service.component.html',
  styleUrls: ['./schedule-service.component.css']
})
export class ScheduleServiceComponent implements OnInit {

  public pickupDays: Array<any>;
  public pickupDay = 0;

  public customer: any = {};
  public binArray: Array<any> = [];
  public binCount = 1;
  public orders = [];
  public isProcessing: boolean = false;


  constructor(private afa: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions, private userService: UserService,private router: Router, private config: ConfigService) { }

  ngOnInit() {
    this.pickupDays = this.getOnDemandDays();
    this.isProcessing = false;

    this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).valueChanges().subscribe((customer: any) => {
      if(!customer) return;
      if(customer.status == "abandoned" || customer.status == "hidden") {
        if(this.userService.isInServiceArea(customer)) {
          this.router.navigate(['/signup/step2']);
        } else {
          this.router.navigate(['signup','coming-soon']);
        }
      }
      if(!customer.scent) {
        customer.scent = "None";
      } 
      if(!customer.waterTemp) {
        customer.waterTemp = "Cold";
      }
      this.customer = customer;
      this.binArray = Array.from(new Array(customer.bincount),(val,index)=>index+1);
      this.binCount = customer.bincount;
    });
  }

  public getOnDemandDays(): Array<any> {
    const today = moment().hour(20).minute(0).second(0); // today at 8pm
    const days: Array<any> = [];
    const nextDay = moment(today);

    while(days.length < 5) {
      nextDay.add(1, 'days');
      while(nextDay.day() < 1 || nextDay.day() > 4) {
        nextDay.add(1, 'days');
      }
      // skip christmas
      if(nextDay.month() == 11 && (nextDay.date() == 24 || nextDay.date() == 25)) continue;
      days.push({
        date: nextDay.toDate(),
        label: nextDay.format("dddd, MMMM Do")
      })
    }
    
    return days;
  }

  public async scheduleOrder() {
    this.isProcessing = true;
    const order = {
      date: this.pickupDays[this.pickupDay].date.valueOf(),
      bincount: this.binCount,
      phone: this.customer.phone,
      instructions: this.customer.instructions,
      reminders: this.customer.reminders,
      waterTemp: this.customer.waterTemp,
      scent: this.customer.scent,
    };

    await this.aff.functions.httpsCallable('placeOnDemandOrder')(order);
    await this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
        phone: this.customer.phone,
        instructions: this.customer.instructions,
        reminders: this.customer.reminders,
        waterTemp: this.customer.waterTemp,
        scent: this.customer.scent,
        softener: !!this.customer.softener,
      });
    this.router.navigate(['/settings']);
    
    
  }

}
