export const environment = {
  production: true,
  stripe: {
    publicKey: 'pk_live_3Mst8OOoIEy6siy2rmxZOD5c',
  },
  firebase: {
    apiKey: "AIzaSyBjP78TsWZx3eXj3V2HGZ_AhJeBnd_ezw4",
    authDomain: "fairmeadow-prod.firebaseapp.com",
    databaseURL: "https://fairmeadow-prod.firebaseio.com",
    projectId: "fairmeadow-prod",
    storageBucket: "fairmeadow-prod.appspot.com",
    messagingSenderId: "154879729774"

  },
  scents: {
    "Downy Unstopables": {
      "Downy Fresh": "Fresh (Airy & Vibrant)",
      "Downy Lush": "Lush (Lavender & Vanilla)",
      "Downy Shimmer": "Shimmer (Flowery & Fruity)",
      "Downy Spring": "Spring (April Fresh & Blooming)",
      "Original Tide": "Classic Tide"
    },
    "Method": {
      "Method Spring Garden": "Spring Garden (Burst of Fresh Flowers)",
      "Method Ginger Mango": "Ginger Mango (Fruity with some Zing)",
      "Method Beach Sage": "Beach Sage (Woody with Ocean Breeze)"
    }
  },
  
  google: {
    mapsKey: "AIzaSyAGHSjeKJ78QH9rJgj7JD29ABUNlRDWR9E"
  }
};
