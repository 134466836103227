

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { take, tap, mergeMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class StaffGuard implements CanActivate, CanLoad {
  constructor(public af: AngularFireAuth, public afs: AngularFirestore, public router: Router) {}

  public staff: boolean = null;
  public cachedUId: string = null;

  // TODO: key this off of a flag in users instead of a match pattern
  canActivate(): Observable<boolean> {
    return this.isStaff().pipe(
      tap(staff => {
        if (!staff) {
          this.router.navigate(['/']);
        }
      }));
  }

  isStaff(): Observable<boolean> {
    return this.af.authState.pipe(
      take(1),
      mergeMap(async (user): Promise<boolean> => {
        if(!user) {
          return false;
        }
        if(this.staff === null || this.cachedUId !== user.uid) {
          const doc = await this.afs.collection('employees').doc(user.uid).ref.get();
          if(doc.exists) {
            this.staff = true;
          } else {
            this.staff = false;
          }
          this.cachedUId = user.uid;
        }
        return this.staff;
      }));
  }

  canLoad(): any {
    return this.isStaff();
  }
}
