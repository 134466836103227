<section class="bg--secondary">
  <div class="container">
      <div class="row">
        
          <div class="col-md-12 offset-lg-2 col-lg-8  text-center">
              <h1>
                Christmas Schedule 2018
              </h1>
              <p class="lead">Happy Holidays! In order to give our employees some much deserved family time over the holiday season we will be operating on a different schedule than
                normal. Read below for specifics regarding the schedule and options you have if you won't be in town.
              </p>
          </div>
      </div>
      <!--end of row-->
  </div>
  <!--end of container-->
</section>
<section>
  <div class="container">
      <div class="row">
          <div class="col-md-12 col-lg-10 offset-lg-1">
              <div class="feature feature-5 boxed boxed--lg boxed--border">
                <div class="row">
                  <div class="col-md-12">
                      <div class="">
                          <h3>Schedule Details</h3>
                          <p class="lead">
                              From Monday, December 24th, until Saturday January 5th we will be moving everyone's laundry day to 2 days after their normal day. So if your day is on Monday, over the holidays 
                              it will be on Wednesday, if your day is Tuesday it will be on Thursday, etc. If you are a Thursday customer then your day will be on Saturday. If for any reason this is going to
                              cause you significant inconvenience please reach out to us. We will do whatever we can to accomodate your schedule.
                          </p>
                      </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="col-md-12 col-lg-10 offset-lg-1">
              <div class="feature feature-5 boxed boxed--lg boxed--border">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <h3>Away for the Holidays? Skip Service!</h3>
                        <p class="lead">
                          To prepare for the holidays we implemented a feature in our software that allows you to skip service whenever you are out of town or otherwise can't get your bin out.
                          Please use this feature if you are traveling over the coming weeks. To use it, simply login to the site and press the "Skip Service" button at the top of the settings screen.
                          You will be credited the full amount for the service if you use the skip feature before midnight Denver time on the day before your service day. If you've already told us you'll be
                          out of town then don't worry, we've pressed the button for you! Enjoy your trip!
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
          </div>

      </div>
      <!--end of row-->
  </div>
  <!--end of container-->
</section>

<section class="text-center bg--secondary">
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <h2>Happy Holidays and Thank You!</h2>
          </div>
      </div>
      <!--end of row-->
  </div>
  <!--end of container-->
</section>

