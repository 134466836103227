
<section class="bg--secondary">
    <div class="container">
        <div class="text-center">
             <h1>Contact Us</h1>
             <h4>Business hours</h4>
             <h5>Monday - Friday 9:00a.m. to 5:00p.m.</h5>
        </div>

        <div class="text-center">
            <h5>Email</h5>
            <p>
                Send us an email at <a href="support@fairmeadow.app">support@fairmeadow.app</a>
            </p>
        </div>

        <div class="text-center">
            <h5>Call or Text</h5>
            <p>
                For time-sensitive cases you can reach us through call or text at (720)432-0168.
            </p>
            <p>
                Please take into consideration that we reserve the use of this phone number for cases that require immediate attention. <br> <br> If your inquiry is not an emergency or a time sensitive matter please contact us by email at <a href="support@fairmeadow.app">support@fairmeadow.app</a>
            </p>
        </div>

        <!--end of row-->
    </div>
    <!--end of container-->
</section>
