import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { StripeService } from '../../services/stripe.service';
import { UserService } from '../../services/user.service';
import { FacebookService } from '../../services/facebook.service';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})
export class Step2Component implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;
  public card: any;
  public cardHandler = this.onChange.bind(this);
  public city: string = "Denver";
  public state: string = "Colorado";
  
  public instructions: string ="";
  public serviceType: string = "basic";
  public bincount: number = 1;
  public errors: Array<string>;
  public referral: string = "";
  public affiliate: any = null;
  public affiliates: Array<any> = [];

  constructor(private afAuth: AngularFireAuth, private afDb: AngularFirestore, private router: Router, private cd: ChangeDetectorRef, private userService: UserService, private stripe: StripeService, private facebook: FacebookService) { }

  ngOnInit() {

    this.referral = localStorage.getItem('referredBy');

    this.afDb.collection('customers').doc(this.afAuth.auth.currentUser.uid).valueChanges().subscribe((customer: any) => {
      if(!this.userService.isInServiceArea(customer)) {
        this.router.navigate(['signup','coming-soon']);
      }
    });

    this.afDb.collection('affiliates').snapshotChanges().subscribe((snapshot) => {
      this.affiliates = snapshot.map((d) => { return { id: d.payload.doc.id, ...d.payload.doc.data() }});
    });
  }

  ngAfterViewInit() {
    this.card = this.stripe.elements.create('card', { style:
      {
        base: {
          lineHeight: '21px'
        }
      }
    });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if(error) {
      this.errors.push(error.message);
    } else {
      this.errors = [];
    }
    this.cd.detectChanges();
  }

  public validate() {
    this.errors = [];

    if(this.errors.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  public async saveContact() {
    if(this.validate()) {
      const { token, error } = await this.stripe.client.createToken(this.card);
      if (error) {
        this.errors.push(error);
      } else {
        if(token.card.funding == "prepaid") {
          // don't accept prepaid cards
          this.errors.push("We don't support prepaid cards. Please use a debit or credit card.");
        } else {
          this.afDb.collection('customers').doc(this.afAuth.auth.currentUser.uid).update({
            serviceType: this.serviceType,
            bincount: +this.bincount,
            affiliate: this.affiliate,
            city: this.city,
            state: this.state,
            instructions: this.instructions,
            referredBy: this.referral,
            stripeToken: token,
            reminders: true,
            status: "applicant",
          }).then((result) => {
            this.facebook.trackPurchase();
            (<any>window).gtag('event', 'sign_up', { method : 'E-Mail' });
            (<any>window).gtag('event', 'conversion', {'send_to': 'AW-799654185/s4Q9COf6wJEBEKmCp_0C'});
            this.router.navigate(['signup','thankyou']);
          });
        }
      }
    }
  }
}
