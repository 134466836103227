import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rise-accupucture',
  templateUrl: './rise-accupucture.component.html',
  styleUrls: ['./rise-accupucture.component.css']
})
export class RiseAccupuctureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
