import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '../../services/user.service';
export class LoginComponent {
    constructor(afAuth, afs, router, userService) {
        this.afAuth = afAuth;
        this.afs = afs;
        this.router = router;
        this.userService = userService;
        this.errors = [];
    }
    handleFailedAuthError(error) {
        switch (error.code) {
            case "auth/wrong-password": return "Incorrect email or password.";
            default: return error.message;
        }
    }
    login() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.errors = [];
            try {
                const { user } = yield this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password);
                const employee = yield this.afs.collection('employees').doc(user.uid).ref.get();
                if (employee.exists) {
                    if (employee.data().admin) {
                        this.router.navigate(['/admin']);
                    }
                    else {
                        this.router.navigate(['/staff']);
                    }
                    return;
                }
                const customer = yield this.afs.collection('customers').doc(user.uid).ref.get();
                if (customer.exists && (customer.data().status == "abandoned" || customer.data().status == "hidden") && this.userService.isInServiceArea(customer)) {
                    this.router.navigate(['/signup/step2']);
                    return;
                }
                this.router.navigate(['/settings']);
            }
            catch (error) {
                this.errors.push(this.handleFailedAuthError(error));
            }
        });
    }
    ngOnInit() {
    }
}
