import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare var fbq: any;

@Injectable({
  providedIn: 'root'
})

export class FacebookService {
  
  constructor() { 
    
  }

  public trackPurchase() {
    fbq('track', 'Purchase');
  }
}
