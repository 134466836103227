import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {

  constructor(private afa: AngularFireAuth) { }

  ngOnInit() {
    this.afa.auth.signOut().then((result) => {
      console.log("Signed out automatically.");
    });
  }

}
