<section class="text-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6">
        <div class="cta">
          <h3>Sign-up today and spend more time living life.</h3>
        </div>
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</section>
<section>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="pricing pricing-1 boxed boxed--lg boxed--border">
          <h3>Basic</h3>
          <span class="h2">
            <strong>$17.95/week</strong>
          </span>
          <span class="type--fine-print">Per Bin</span>
          <hr />
          <ul>
            <li>
              <span class="checkmark bg--primary-1"></span>
              <span>1 Laundry Day per Week</span>
            </li>
            <li>
              <span class="checkmark bg--primary-1"></span>
              <span>Next Day Delivery</span>
            </li>
            <li>
              <span class="checkmark bg--primary-1"></span>
              <span>Each bin fits up to 25lbs of laundry</span>
            </li>
            <li>
              <span class="checkmark bg--primary-1"></span>
              <span>30 day minimum commitment</span>
            </li>
          </ul>
          <a class="btn btn--primary" [routerLink]="['/signup']">
            <span class="btn__text"> Sign-Up for Basic </span>
          </a>
        </div>
        <!--end of pricing-->
      </div>
      <div class="col-md-6">
        <div class="pricing pricing-1 boxed boxed--lg boxed--border">
          <h3>Premium</h3>
          <span class="h2">
            <strong>$21.95/week</strong>
          </span>
          <span class="type--fine-print">Per Bin</span>
          <span class="label">Most Popular</span>
          <hr />
          <ul>
            <li>
              <span class="checkmark bg--primary-1"></span>
              <span>Everything in Basic</span>
            </li>
            <li>
              <span class="checkmark bg--primary-1"></span>
              <span>Your Choice of Method and Downy Laundry Scents</span>
            </li>
            <li>
              <span class="checkmark bg--primary-1"></span>
              <span>Customizable Laundry Settings (softener, water temp.)</span>
            </li>
            <li>
              <span class="checkmark bg--primary-1"></span>
              <span>In-Home Delivery Option</span>
            </li>
          </ul>
          <a class="btn btn--primary-1" [routerLink]="['/signup']">
            <span class="btn__text"> Sign-Up for Premium </span>
          </a>
        </div>
        <!--end of pricing-->
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</section>
<section class="bg--secondary">
  <div class="container">
    <div class="text-center">
      <h3>Frequently Asked Questions</h3>
    </div>
    <br />
    <br />
    <div class="row">
      <div class="col-lg-4">
        <div class="text-block">
          <h5>Can you explain your pricing?</h5>
          <p>
            What makes us different from other laundry services is that our
            weekly price is based per bin not per pound. For example: If you
            have 2 bins, that is up to 50 pounds of laundry each week. If it
            fits in the bin comfortably* you are good to go!
          </p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="text-block">
          <h5>How big is a bin?</h5>
          <p>
            We think you will be pleasantly surprised by how much fits in a bin.
            They are 15-gallon durable bins that fit up to 25 pounds of
            laundry*.
          </p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="text-block">
          <h5>How does In-Home Delivery work?</h5>
          <p>
            With In-Home delivery, we bring the laundry inside your house, even
            if you're not home! We do this by keeping a garage code or by using
            a key that you can put in a lockbox on your property. Premium
            service is recommended if you would like to have a secure place for
            us to pick up and deliver your laundry.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <small
          >*Please be mindful when filling your bin. The lid must close
          comfortably, flat, and without bulging.</small
        >
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</section>
<footer class="text-center-xs space--xs bg--dark">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <ul class="list-inline">
          <li>
            <a href="#">
              <span class="h6 type--uppercase">About</span>
            </a>
          </li>
          <li>
            <a href="#">
              <span class="h6 type--uppercase">Careers</span>
            </a>
          </li>
          <li>
            <a href="#">
              <span class="h6 type--uppercase">Support</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-md-5 text-right text-center-xs">
        <a class="type--fine-print" href="#">support@fairmeadow.app</a>
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</footer>
