import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
// TODO: Properly implement provider pattern for this
function getWindow() {
    return window;
}
export class RecoverComponent {
    constructor(afa) {
        this.afa = afa;
        this.email = "";
    }
    ngOnInit() {
    }
    sendPasswordReset() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const window = getWindow();
            const url = `${window.location.protocol}//${window.location.host}/auth/login`;
            yield this.afa.auth.sendPasswordResetEmail(this.email, {
                url,
            });
        });
    }
}
