<section>
    <div class="container">
        <div class="col-md-12">
            <div class="boxed boxed--lg boxed--border" *ngIf="canChange === null">
              <h2>Loading order information...</h2>
              <span>Please wait while order information is loaded from the database.</span>
            </div>
            <div class="boxed boxed--lg boxed--border" *ngIf="canChange === false">
              <h2>
                Order can no longer be edited.
              </h2>
              <span>This order has already been scheduled and cannot be edited. If this is an emergency please contact support.</span>
            </div>
            <div class="boxed boxed--lg boxed--border" *ngIf="canChange === true">
                <h2>Edit Order</h2>
                <hr>
                <form>
                    <div class="form-group">
                        <label for="card-info">Pickup Day</label>
                        <div class="input-select">
                          <select name="pickupDay" [(ngModel)]="pickupDay">
                              <option *ngFor="let day of pickupDays; let i = index;" [value]="i">{{day.label}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="card-info">Number of Bins</label>
                        <div class="input-select">
                        <select name="order.bincount" [(ngModel)]="order.bincount">
                            <option *ngFor="let i of binArray" [value]="i">{{i}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="phone">Phone Number</label>
                        <input type="text" class="form-control" id="phone" placeholder="Phone Number" name="phone" [(ngModel)]="order.phone">
                    </div>
                    
                    <div class="form-group">
                            <label for="instructions">Pickup/Drop-off Instructions</label>
                            <textarea name="instructions" [(ngModel)]="order.instructions" class="form-control" id="instructions" rows="4" placeholder="Example: Please enter through the garage via the alleyway. the code is 1234. Leave the bins by the bikerack"></textarea>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" name="reminders" class="form-check-input" id="reminders" [(ngModel)]="order.reminders">
                        <label class="form-check-label" for="reminders">Remind me the night before my laundry is picked up (reminders are sent around 6pm)</label>
                    </div>
                    
                </form>
                <br/><br/>
                <h2>Laundry Settings</h2>
                <hr>
                <form>
                    <div class="form-group">
                        <label for="card-info">Water Temperature</label>
                        <div class="input-select">
                            <select name="waterTemp" [(ngModel)]="order.waterTemp">
                              <option value="Cold">Cold (Recommended)</option>
                              <option value="Warm">Warm</option>
                              <option value="Hot">Hot</option>
                            </select>
                          </div>
                    </div>
                    <div class="form-group">
                        <label for="card-info">Laundry Scent</label>
                        <div class="input-select">
                            <select name="scent" [(ngModel)]="order.scent">
                                <option value="None">None</option>
                                <optgroup *ngFor="let group of config.getScents()" [label]="group.value">
                                    <option *ngFor="let option of group.children" [value]="option.key">{{option.value}}</option>
                                </optgroup>
                            </select>
                          </div>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" name="reminders" class="form-check-input" id="reminders" [(ngModel)]="order.softener">
                        <label class="form-check-label" for="reminders">Add All Natural Eco-Friendly Fabric Softener (Made by FairMeadow!)</label>
                    </div>
                    <div class="form-group text-right">
                        <a id="payment" class="btn btn--primary type--uppercase" (click)="saveChanges()"><span class="btn__text">Save Order</span></a>    
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
