import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { flatMap } from 'rxjs/operators';
import { tap, take } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate, CanLoad {
  constructor(public af: AngularFireAuth, public afs: AngularFirestore, public router: Router) {}

  // TODO: key this off of a flag in users instead of a match pattern
  canActivate(): Observable<boolean> {
    return this.isAdministrator().pipe(
      tap(admin => {
        if (!admin) {
          this.router.navigate(['/']);
        }
      }));
  }

  isAdministrator(): Observable<boolean> {
    return this.af.authState.pipe(
      take(1),
      flatMap((val) => this.getAdminFlag(val))
    );
  };

  async getAdminFlag(user): Promise<boolean> {
    if(!user) return false;
    const userRecord = await this.afs.collection('employees').doc(user.uid).ref.get();
    if(!userRecord.exists) return false;
    return userRecord.data().admin;
  }

  canLoad(): any {
    return this.isAdministrator();
  }
}
