import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

// TODO: Properly implement provider pattern for this
function getWindow (): any {
  return window;
}

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.css']
})
export class RecoverComponent implements OnInit {

  public email: string = "";

  constructor(
    private afa: AngularFireAuth,
  ) { }

  ngOnInit() {
  }

  public async sendPasswordReset() {
    const window = getWindow();
    const url = `${window.location.protocol}//${window.location.host}/auth/login`;
    await this.afa.auth.sendPasswordResetEmail(this.email, {
      url,
    });
  }
}