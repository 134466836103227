import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from '@firebase/app';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private afs: AngularFirestore) {

  }

  public isInServiceArea(argument: any): boolean {
    let zipcode: string;
    if(typeof argument.zipcode == "string") {
      zipcode = argument.zipcode;
    } else {
      zipcode = argument;
    }
    
    if(!zipcode) return true;
    if(/^\s*(80238|80207|80218|80220|80206|80246|80022|80230|80203|80209|80224|80211|80239|80249)(-(\d*))?\s*$/.test(zipcode)) {
      return true;
    } else {
      return false;
    }
  }

}
