import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-l-style-bar',
  templateUrl: './l-style-bar.component.html',
  styleUrls: ['./l-style-bar.component.css']
})
export class LStyleBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
