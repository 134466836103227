import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dearabby',
  templateUrl: './dearabby.component.html',
  styleUrls: ['./dearabby.component.css']
})
export class DearabbyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
