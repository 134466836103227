import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { LoginComponent } from './auth/login/login.component';
import { Step1Component } from './signup/step1/step1.component';
import { ThankYouComponent } from './signup/thank-you/thank-you.component';
import { Step2Component } from './signup/step2/step2.component';
import { IndexComponent } from './website/index/index.component';
import { PricingComponent } from './website/pricing/pricing.component';
import { FaqComponent } from './website/faq/faq.component';
import { BasicSettingsComponent } from './settings/basic-settings/basic-settings.component';
import { WebsiteComponent } from './layouts/website/website.component';
import { AdminGuard } from './services/admin-guard.service';
import { StaffGuard } from './services/staff-guard.service';
import { RecoverComponent } from './auth/recover/recover.component';
import { ComingSoonComponent } from './signup/coming-soon/coming-soon.component';
import { HowitworksComponent } from './website/howitworks/howitworks.component';
import { ScheduleServiceComponent } from './settings/schedule-service/schedule-service.component';
import { EditOrderComponent } from './orders/edit-order/edit-order.component';
import { ChristmasComponent } from './announcements/christmas/christmas.component';
import { RiseAccupuctureComponent } from './videos/rise-accupucture/rise-accupucture.component';
import { LStyleBarComponent } from './videos/l-style-bar/l-style-bar.component';
import { DearabbyComponent } from './videos/dearabby/dearabby.component';
import { ContactusComponent } from './website/contactus/contactus.component';

const routes: Routes = [
  {
    path: '',
    component: WebsiteComponent,
    children: [
      {
        component: IndexComponent,
        path: '',
      },
      {
        component: PricingComponent,
        path: 'pricing',
      },
      {
        component: ContactusComponent,
        path: 'contactus',
      },
      {
        component: FaqComponent,
        path: 'faq',
      },
      {
        component: HowitworksComponent,
        path: 'howitworks',
      },
      { 
        component: ChristmasComponent,
        path: 'announcements/christmas'
      },
      { 
        component: RiseAccupuctureComponent,
        path: 'videos/rise-acupucture'
      },
      { 
        component: LStyleBarComponent,
        path: 'videos/l-style-bar'
      },
      { 
        component: DearabbyComponent,
        path: 'videos/dearabbytutors'
      },
      {
        component: LoginComponent,
        path: 'auth/login',
      },
      {
        component: RecoverComponent,
        path: 'forgot-password',
      },
      {
        component: Step1Component,
        path: 'signup',
      },
      {
        component: Step2Component,
        path: 'signup/step2',
        canActivate: [ AuthGuard ],
      },
      {
        component: ThankYouComponent,
        path: 'signup/thankyou'
      },
      {
        component: ComingSoonComponent,
        path: 'signup/coming-soon',
        canActivate: [ AuthGuard ]
      },
      {
        component: BasicSettingsComponent,
        path: 'settings',
        canActivate: [ AuthGuard ]
      },
      {
        component: ScheduleServiceComponent,
        path: 'settings/schedule-service',
        canActivate: [ AuthGuard ]
      },
      {
        component: EditOrderComponent,
        path: 'orders/:id',
        canActivate: [ AuthGuard ]
      },
    ]
  },
  { 
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    path: 'admin',
    canActivate: [ AdminGuard ],
    canLoad: [ AdminGuard ],
  },
  { 
    loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule),
    path: 'staff',
    canActivate: [ StaffGuard ],
    canLoad: [ StaffGuard ],
  },
  {
    loadChildren: () => import('./bins/bins.module').then(m => m.BinsModule),
    path: 'bins',
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  providers: [AuthGuard, AdminGuard, StaffGuard],
})
export class AppRoutingModule { }