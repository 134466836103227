import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { LoginComponent } from './auth/login/login.component';
import { Step2Component } from './signup/step2/step2.component';
import { RecoverComponent } from './auth/recover/recover.component';
import { AppRoutingModule } from './app-routing.module';
import { ThankYouComponent } from './signup/thank-you/thank-you.component';
import { Step1Component } from './signup/step1/step1.component';
import { BasicSettingsComponent } from './settings/basic-settings/basic-settings.component';
import { IndexComponent } from './website/index/index.component';
import { PricingComponent } from './website/pricing/pricing.component';
import { FaqComponent } from './website/faq/faq.component';
import { WebsiteComponent } from './layouts/website/website.component';
import { ComingSoonComponent } from './signup/coming-soon/coming-soon.component';
import { TextMaskModule } from 'angular2-text-mask';
import { HowitworksComponent } from './website/howitworks/howitworks.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScheduleServiceComponent } from './settings/schedule-service/schedule-service.component';
import { EditOrderComponent } from './orders/edit-order/edit-order.component';
import { ChristmasComponent } from './announcements/christmas/christmas.component';
import { RiseAccupuctureComponent } from './videos/rise-accupucture/rise-accupucture.component';
import { LStyleBarComponent } from './videos/l-style-bar/l-style-bar.component';
import { DearabbyComponent } from './videos/dearabby/dearabby.component';
import { ContactusComponent } from './website/contactus/contactus.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    Step1Component,
    RecoverComponent,
    WebsiteComponent,
    ThankYouComponent,
    Step2Component,
    BasicSettingsComponent,
    IndexComponent,
    PricingComponent,
    FaqComponent,
    ComingSoonComponent,
    HowitworksComponent,
    ScheduleServiceComponent,
    EditOrderComponent,
    ChristmasComponent,
    RiseAccupuctureComponent,
    LStyleBarComponent,
    DearabbyComponent,
    ContactusComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    TextMaskModule,
    AppRoutingModule,
    OverlayModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
