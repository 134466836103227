import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css']
})
export class Step1Component implements OnInit  {

  public firstName: string;
  public lastName: string;
  public email: string;
  public address: string;
  public zipcode: string;
  public password: string;
  public phone: string ="";
  public confirmPassword: string;
  public errors: Array<string> = [];
  public acceptTerms: boolean = false;
  public referral: string = "";

  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(private afAuth: AngularFireAuth, private afDb: AngularFirestore, private userService: UserService,private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    const referredBy = this.route.snapshot.queryParams['ref'];
    console.log(referredBy);
    if(referredBy) {
      this.referral = referredBy;
      localStorage.setItem('referredBy', referredBy);
    } else {
      this.referral = localStorage.getItem('referredBy');
    }
  }
  
  public validate() {
    this.errors = [];
    if(!this.acceptTerms) {
      this.errors.push("You must accept the privacy policy and terms of service.")
    }
    if(!this.firstName || !this.lastName) {
      this.errors.push("You must enter your first and last name.");
    }

    if(!this.phone) {
      this.errors.push("You must enter your phone number.");
    }

    if(!this.email || !(/^[a-zA-Z0-9.!#$%&â€™*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email))) {
      this.errors.push("You must enter a valid email address.");
    }

    if(!this.password) {
      this.errors.push("You must enter a password.");
    }

    if(!this.address) {
      this.errors.push("You must enter your address.");
    }

    if(!this.zipcode) {
      this.errors.push("You must enter your zip code.");
    }

    if(this.errors.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  public createAccount() {
    if(this.validate()) {
      this.afAuth.auth.createUserWithEmailAndPassword(this.email, this.password).then((result) => {
        this.afDb.collection('customers').doc(result.user.uid).set({
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
          address: this.address,
          createdAt: new Date(),
          zipcode: this.zipcode,
          status: "abandoned",
        }).then((result) => {
          if(this.userService.isInServiceArea(this.zipcode)) {            
            this.router.navigate(['signup','step2']);
          } else {
            this.router.navigate(['signup','coming-soon']);
          }
        });
      }, (reason) => {
        this.errors.push(reason.message);
      });
    }
  }
}
