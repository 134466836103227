<section>
    <div class="container">
        <div class="row">
            <div class="col"></div>
            <div class="col-md-6 col-xs-12">
              <div class="card">
                <div class="card-body">
                    <h3 class="card-title text-center">Forgot your password?</h3>
                    <p>
                      Don't worry, it happens all the time. Enter your email address below and we will send you a link to reset your password.
                    </p>
                    <form class="form-signin">
                      <div class="form-group">
                          <label for="inputEmail" class="sr-only">Email address</label>
                          <input type="email" name="email" id="inputEmail" class="form-control" placeholder="Email address" required autofocus [(ngModel)]="email">    
                      </div>
                      <div class="form-group">
                          <button class="btn btn--primary type--uppercase" type="submit" (click)="sendPasswordReset()">Send Password Reset Email</button>
                      </div>
                    </form>
                 </div>
                </div>
              </div>
            <div class="col"></div>
        </div>
    </div>
</section>