/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./thank-you.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./thank-you.component";
import * as i3 from "@angular/fire/auth";
var styles_ThankYouComponent = [i0.styles];
var RenderType_ThankYouComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThankYouComponent, data: {} });
export { RenderType_ThankYouComponent as RenderType_ThankYouComponent };
export function View_ThankYouComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "col-md-6 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "card-header text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank you!"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We've started working on your subscription. We will reach out to you shortly to let you know your laundry day and arrange for delivery of your bins! "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "col"]], null, null, null, null, null))], null, null); }
export function View_ThankYouComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-thank-you", [], null, null, null, View_ThankYouComponent_0, RenderType_ThankYouComponent)), i1.ɵdid(1, 114688, null, 0, i2.ThankYouComponent, [i3.AngularFireAuth], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThankYouComponentNgFactory = i1.ɵccf("app-thank-you", i2.ThankYouComponent, View_ThankYouComponent_Host_0, {}, {}, []);
export { ThankYouComponentNgFactory as ThankYouComponentNgFactory };
