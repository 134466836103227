/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coming-soon.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./coming-soon.component";
var styles_ComingSoonComponent = [i0.styles];
var RenderType_ComingSoonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ComingSoonComponent, data: {} });
export { RenderType_ComingSoonComponent as RenderType_ComingSoonComponent };
export function View_ComingSoonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "col-md-6 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "card-header text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Coming Soon"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Thank you for your interest in our service! Unfortunately we don't serve your neighborhood yet. We are working hard every day to increase our service area and will let you know as soon as we are able to sign you up! "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "col"]], null, null, null, null, null))], null, null); }
export function View_ComingSoonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-coming-soon", [], null, null, null, View_ComingSoonComponent_0, RenderType_ComingSoonComponent)), i1.ɵdid(1, 114688, null, 0, i2.ComingSoonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ComingSoonComponentNgFactory = i1.ɵccf("app-coming-soon", i2.ComingSoonComponent, View_ComingSoonComponent_Host_0, {}, {}, []);
export { ComingSoonComponentNgFactory as ComingSoonComponentNgFactory };
