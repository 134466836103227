import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public email: string;
  public password: string;
  public errors: Array<string> = [];

  constructor(public afAuth: AngularFireAuth, public afs: AngularFirestore, private router: Router, private userService: UserService) {
  }

  private handleFailedAuthError(error: any): string {
    switch(error.code) {
      case "auth/wrong-password": return "Incorrect email or password.";
      default: return error.message;
    }
  }

  public async login() {
    this.errors = [];
    try {
      const { user } = await this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password);
      const employee = await this.afs.collection('employees').doc(user.uid).ref.get();
      if(employee.exists) {
        if(employee.data().admin) {
          this.router.navigate(['/admin']);
        } else {
          this.router.navigate(['/staff']);
        }
        return;
      }
      const customer = await this.afs.collection('customers').doc(user.uid).ref.get();
      if(customer.exists && (customer.data().status == "abandoned" || customer.data().status == "hidden") && this.userService.isInServiceArea(customer)) {
        this.router.navigate([ '/signup/step2' ]);
        return;
      }
      this.router.navigate(['/settings']);
    } catch(error) {
      this.errors.push(this.handleFailedAuthError(error));
    }
  }

  ngOnInit() {
  }

}
