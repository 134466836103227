<section class="bg--secondary">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1>
                  How it Works
                </h1>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="feature feature-5 boxed boxed--lg boxed--border">
                  <div class="row">
                    <div class="col-md-10">
                        <div class="">
                            <h5>Sign-Up Online</h5>
                            <p>
                                After signing up you will receive an onboarding email going over all of the details about our service and what you need to do to get started. This will include your assigned service day and the date of your first service.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <span class="number">1</span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="feature feature-5 boxed boxed--lg boxed--border">
                    <div class="row">
                      <div class="col-md-10">
                        <div class="">
                          <h5>Laundry Day</h5>
                            <p>
                              Your laundry day will be the same day each week. Fill your bins and set them on your porch or in your assigned pickup spot if you have premium service. Pickup windows vary depending on our daily routes so we ask all customers to have bin(s) ready by 7:00a.m.
                            </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                          <span class="number">2</span>
                      </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="feature feature-5 boxed boxed--lg boxed--border">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="">
                                <h5>Receive your clean laundry</h5>
                                <p>
                                    Your clean and folded laundry will be returned the following day. The delivery window will vary depending on our daily routes.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <span class="number">3</span>
                        </div>
                        
                      </div>
                   
                </div>
            </div>

            <div class="col-md-12">
                <div class="feature feature-5 boxed boxed--lg boxed--border">
                    <div class="row">
                        <!--
                      <div class="col-md-3">
                          <img alt="Image" class="border--round" src="/assets/inner-6.jpg" />
                      </div>
                    -->
                      <div class="col-md-10">
                        <div class="">
                          <h5>Continued service</h5>
                          <p>
                            FairMeadow will continue providing service on a weekly basis on your assigned laundry day unless you skip service or pause your account.
                          </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                          <span class="number">4</span>
                      </div>
                      
                      
                    </div>
                </div>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>

<section class="text-center bg--secondary">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>The best laundry service in Denver, just ask our customers.</h2>
                <p class="lead">
                  We strive to provide the best service at a fraction of the cost, and it shows.
                </p>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>

<section class=" ">
    <div class="container">
        <div class="row">
            <div class="col"></div>
            <div class="col-lg-3">
                <div>
                    <span>&ldquo; I just did this for the first time today and I’m obsessed!! I definitely recommend it!&rdquo;
                    </span>
                    <h5 class="testimonial">&mdash;Josephine Davis</h5>
                    <span>Denver, CO</span>
                </div>
            </div>
            <div class="col"></div>
            <div class="col-lg-3">
                <div>
                    <span>&ldquo;This service has been life changing! Laundry use to be a heated discussion in our house, but now it has become a topic of excitement, excitement to tell everyone about Fairmeadow. You all must try it, as you will not regret it.&rdquo;
                    </span>
                    <h5 class="testimonial">&mdash;Rebecca Bitti</h5>
                    <span>Denver, CO</span>
                </div>       
            </div>
            <div class="col"></div>
            <div class="col-lg-3">
                <div>
                    <span>&ldquo;Khara and her team are amazing! They have been so prompt and professional...I’m in heaven with this service!&rdquo;
                    </span>
                    <h5 class="testimonial">&mdash;Liz Gonzalez-Lara</h5>
                    <span>Denver, CO</span>
                </div>
            </div>      
            <div class="col"></div>       
        </div>
    </div>
</section>

<!-- <footer class="text-center-xs space--xs bg--dark ">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <ul class="list-inline">
                    <li>
                        <a href="#">
                            <span class="h6 type--uppercase">About</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span class="h6 type--uppercase">Careers</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span class="h6 type--uppercase">Support</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-md-5 text-right text-center-xs">
                <a class="type--fine-print" href="#">support@fairmeadow.app</a>
            </div>
        </div>
        end of row
    </div>
    end of container
</footer> -->