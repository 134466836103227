
<section>
    <div class="container">
        <div class="row">
            <div class="col"></div>
            <div class="col-md-6 col-xs-12">
                <div class="card">
                <div class="card-header text-center">
                    <h1>Thank you!</h1>    
                </div>
                <div class="card-body">
                    <h4>
                        We've started working on your subscription. We will reach out to you shortly to let you know your laundry day and arrange for delivery of your bins!
                    </h4>
                </div>
                </div>
            </div>
            <div class="col"></div>
        </div>
    </div>
</section>
