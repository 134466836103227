import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { take, map, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(public af: AngularFireAuth, public router: Router) {}

  canActivate(): any {
    return this.af.authState.pipe(
      take(1),
      map(authState => !!authState),
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['auth', 'login']);
        }
        console.log(`authenticated: ${authenticated}`);
      })
    );
  }
}



// import { Injectable } from '@angular/core';
// import * as firebase from 'firebase';

// @Injectable()
// export class AuthGuard {
  
//   resetPassword(email: string) {
//     var auth = firebase.auth();
//     return auth.sendPasswordResetEmail(email)
//       .then(() => console.log("email sent"))
//       .catch((error) => console.log(error))
//   }
  
// }