import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
export class ConfigService {
    constructor() { }
    getScents() {
        return Object.keys(environment.scents).map((k) => {
            return { key: k, value: k, children: Object.keys(environment.scents[k]).map((ck) => {
                    return { key: ck, value: environment.scents[k][ck] };
                }) };
        });
    }
}
ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(); }, token: ConfigService, providedIn: "root" });
