
<section>
    <div class="container">
        <div class="row">
            <div class="col"></div>
            <div class="col-md-6 col-xs-12">
              <div class="card">
                <div class="card-body">
                    <h3 class="card-title text-center">Step 1 - Create Account</h3>
                    <form class="form-signup">
                        <div class="form-group">
                                <div class="form-row">
                                        <div class="col">
                                            <label for="firstName">First Name</label>
                                            <input type="text" name="first" class="form-control" id="firstName" placeholder="First Name" [(ngModel)]="firstName" autofocus>
                                        </div>
                                        <div class="col">
                                            <label for="lastName">Last Name</label>
                                            <input type="text" name="last" class="form-control" id="lastName" placeholder="Last Name" [(ngModel)]="lastName">
                                        </div>
                                    </div>
                        </div>
                        <div class="form-group">
                            <label for="email">Email address</label>
                            <input type="email" class="form-control" name="email" id="email" aria-describedby="emailHelp" placeholder="Enter email" [(ngModel)]="email">
                        </div>
                        <div class="form-group">
                            <label for="password">Password</label>
                            <input type="password" class="form-control" name="password" id="password" placeholder="Password" [(ngModel)]="password">
                        </div>
                        <div class="form-group">
                            <label for="phone">Phone Number</label>
                            <input type="text" class="form-control" id="phone" placeholder="Phone Number" name="phone" [textMask]="{mask: phoneMask}" [(ngModel)]="phone">
                        </div>
                        <div class="form-group">
                            <label for="address">Address</label>
                            <input type="text" class="form-control" id="address" placeholder="Address" name="address" [(ngModel)]="address">
                        </div>
                        <div class="form-group">
                            <label for="state">Zip Code</label>
                            <input type="text" class="form-control" id="zipcode" placeholder="Zip Code" name="zipcode" [(ngModel)]="zipcode">
                        </div>
                        <div class="form-group form-check">
                            <input type="checkbox" name="acceptTerms" class="form-check-input" id="acceptTerms" [(ngModel)]="acceptTerms">
                            <label class="form-check-label" for="acceptTerms">I have read and agree to the FairMeadow <a href="/assets/privacy-policy.html">Privacy Policy</a> and <a href="/assets/service-terms.html">Terms of Service</a>.</label>
                        </div>
                        <div class="form-group" *ngFor="let error of errors">
                            <div class="alert alert-danger" role="alert">
                                {{error}}
                            </div>
                        </div>
                        <br/>
                        <button id="payment" class="btn btn--primary type--uppercase" (click)="createAccount()" type="submit">Continue to Step 2</button>
                        <br/><br/>
                        <div class="form-group" *ngIf="referral">
                            <div class="alert alert-success">
                                Referral code unlocked! You will receive 50% off your first month of service.
                            </div>
                        </div>     
                    </form>
                </div>
              </div>
                
            </div>
            <div class="col"></div>
        </div>
    </div>
</section>
