<div class="container">
    <nav class="navbar navbar-expand navbar-light bg-white">
        <a class="navbar-brand" [routerLink]="['/']">
          <img src="/assets/FairMeadowHorizontal.png" alt="">
        </a>
        <ul class="navbar-nav ml-auto" *ngIf="afAuth.user | async as user; else showUnauthenticated">
            <li *ngIf="isStaff">
                <a class="nav-link" [routerLink]="['/staff']">Staff</a>
            </li>
            <li *ngIf="isAdmin">
                <a class="nav-link" [routerLink]="['/admin']">Admin</a>
            </li>
            <li>
                <a class="nav-link" [routerLink]="['/settings']">Settings</a>
            </li>
            <li>
                <a class="btn btn--sm btn--primary type--uppercase" (click)="logout()">
                    <span class="btn__text">Sign&nbsp;Out</span>
                </a>
            </li>
        </ul>
    </nav>
</div>

<router-outlet></router-outlet>

<ng-template #showUnauthenticated>
    <ul class="navbar-nav ml-auto">
        <li>
            <a class="nav-link" [routerLink]="['/howitworks']">How It Works</a>
        </li>
        <li>
            <a class="nav-link" [routerLink]="['/pricing']">Pricing</a>
        </li>
        <li>
            <a class="nav-link" [routerLink]="['/faq']">FAQ</a>
        </li>
        <li>
            <a class="nav-link" [routerLink]="['/contactus']">Contact Us</a>
        </li>
        <li>
            <a class="btn btn--sm btn--primary type--uppercase" [routerLink]="['/auth/login']">
                <span class="btn__text">
                    Sign&nbsp;In
                </span>
            </a>
        </li>
    </ul>
</ng-template>