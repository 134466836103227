<section>
  <div class="container">
    <div class="row">
      <div class="col"></div>
      <div class="col-md-6 col-xs-12">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title text-center">
              Service Level &amp; Instructions
            </h3>
            <form class="form-signup">
              <div class="form-group">
                <div class="form-row">
                  <div class="col">
                    <label for="serviceType">Service</label>
                    <select
                      class="form-control"
                      id="serviceType"
                      name="serviceType"
                      [(ngModel)]="serviceType"
                    >
                      <option value="basic">Basic ($17.95/week)</option>
                      <option value="premium">Premium ($21.95/week)</option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="bincount">Number of Bins</label>
                    <select
                      class="form-control"
                      id="bincount"
                      name="bincount"
                      [(ngModel)]="bincount"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="card-info"
                  >Card Info - Your card will not be charged until bins are
                  accepted.</label
                >
                <div id="card-info" class="form-control" #cardInfo></div>
              </div>
              <div class="form-group">
                <label for="affiliate">Donate 5% to the Following School</label>
                <select
                  class="form-control"
                  id="affiliate"
                  name="affiliate"
                  [(ngModel)]="affiliate"
                >
                  <option [ngValue]="null">None</option>
                  <option
                    *ngFor="let affiliate of affiliates"
                    [value]="affiliate.id"
                  >
                    {{ affiliate.display_name }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="instructions">Pickup/Drop-off Instructions</label>
                <textarea
                  name="instructions"
                  [(ngModel)]="instructions"
                  class="form-control"
                  id="instructions"
                  rows="4"
                  placeholder="Example: Please enter through the garage via the alleyway. the code is 1234. Leave the bins by the bikerack"
                ></textarea>
              </div>
              <div class="form-group" *ngFor="let error of errors">
                <div class="alert alert-danger" role="alert">
                  {{ error }}
                </div>
              </div>

              <button
                id="payment"
                class="btn btn--primary type--uppercase"
                (click)="saveContact()"
                type="submit"
              >
                Sign Up
              </button>
              <br /><br />
              <div class="form-group" *ngIf="referral">
                <div class="alert alert-success">
                  Referral code unlocked! You will receive 50% off your first
                  month of service.
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
</section>
<section class=" ">
  <div class="container">
    <div class="row">
      <div class="col"></div>
      <div class="col-lg-3">
        <div>
          <span
            >&ldquo;Shout out to Fair Meadow laundry service! They provide
            terrific service and they are wonderful people. I’m so grateful and
            happy to have them! I filled three bins tonight and they’ll be
            returned tomorrow fresh, clean and folded. Yay!!&rdquo;
          </span>
          <h5 class="testimonial">&mdash;Julie Herzog</h5>
        </div>
      </div>
      <div class="col"></div>
      <div class="col-lg-3">
        <div>
          <span
            >&ldquo;We began utilizing their service in May and it’s been
            wonderful. Thumbs up!&rdquo;
          </span>
          <h5 class="testimonial">&mdash;Gretchen Everett</h5>
        </div>
      </div>
      <div class="col"></div>
      <div class="col-lg-3">
        <div>
          <span
            >&ldquo;This service has been life changing! Laundry use to be a
            heated discussion in our house, but now it has become a topic of
            excitement, excitement to tell everyone about Fairmeadow. You all
            must try it, as you will not regret it.&rdquo;
          </span>
          <h5 class="testimonial">&mdash;Rebecca Bitti</h5>
        </div>
      </div>

      <div class="col"></div>
    </div>
  </div>
</section>
