import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AdminGuard } from '../../services/admin-guard.service';
import { StaffGuard } from '../../services/staff-guard.service';

@Component({
  selector: 'app-layout',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.css']
})
export class WebsiteComponent implements OnInit {

  public isAdmin: boolean = false;
  public isStaff: boolean = true;
  constructor(
    public afAuth: AngularFireAuth, 
    public adminGuard: AdminGuard,
    public staffGuard: StaffGuard,
    private router: Router) {
  }

  logout() {
    this.afAuth.auth.signOut().then((result) => {
      this.router.navigate(['/']);
    });
  }

  ngOnInit() {
    this.adminGuard.isAdministrator().subscribe((result) => {
      this.isAdmin = result;
    });
    this.staffGuard.isStaff().subscribe((result) => {
      this.isStaff = result;
    });
  }

}
