import * as tslib_1 from "tslib";
import { CanActivate, Router, CanLoad } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { flatMap } from 'rxjs/operators';
import { tap, take } from 'rxjs/operators';
export class AdminGuard {
    constructor(af, afs, router) {
        this.af = af;
        this.afs = afs;
        this.router = router;
    }
    // TODO: key this off of a flag in users instead of a match pattern
    canActivate() {
        return this.isAdministrator().pipe(tap(admin => {
            if (!admin) {
                this.router.navigate(['/']);
            }
        }));
    }
    isAdministrator() {
        return this.af.authState.pipe(take(1), flatMap((val) => this.getAdminFlag(val)));
    }
    ;
    getAdminFlag(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!user)
                return false;
            const userRecord = yield this.afs.collection('employees').doc(user.uid).ref.get();
            if (!userRecord.exists)
                return false;
            return userRecord.data().admin;
        });
    }
    canLoad() {
        return this.isAdministrator();
    }
}
