<div class="main-container">
  <section class="text-center height-80">
    <div class="container">
      <div class="row">
        <div class="offset-3 col-9">
          <h2>Frequently Asked Questions</h2>
          <br>
          <p class="lead">These are the questions we are asked a lot by customers...</p>
          <div class="row text-left">
            <div class="col-12">
              <div class="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">




                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      <h5 class="mb-0">
                        How much laundry fits in a bin? <i class="fa fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <!-- Card body -->
                  <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                    <div class="card-body">
                      We think you will be pleasantly surprised by how much fits in a bin. They are 15 gallon bins that fit up to 25lbs of laundry.
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->


                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingTwo">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <h5 class="mb-0">
                        Who will be washing my laundry?<i class="fa fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <!-- Card body -->
                  <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                    <div class="card-body">
                      Our staff were previously professionals in home services, hospitality or commercial laundries. With years of industry experience,
                      they are experts in the proper way to clean every type of clothing. Your laundry is in good hands!
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->

                <!-- Accordion card -->
                <div class="card">

                    <!-- Card header -->
                    <div class="card-header" role="tab" id="headingOne">
                      <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseScent" aria-expanded="false" aria-controls="collapseScent">
                        <h5 class="mb-0">
                          What if I want to add a scent to my laundry?<i class="fa fa-angle-down rotate-icon"></i>
                        </h5>
                      </a>
                    </div>
  
                    <!-- Card body -->
                    <div id="collapseScent" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                      <div class="card-body">
                        Our Premium service allows you to select a scent to be applied to your laundry. We have a large selection of scents to choose from by Downy and Method brands.
                      </div>
                    </div>
                  </div>
                  <!-- Accordion card -->

                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSoftener" aria-expanded="false" aria-controls="collapseSoftener">
                      <h5 class="mb-0">
                        What about fabric softener?<i class="fa fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <!-- Card body -->
                  <div id="collapseSoftener" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                    <div class="card-body">
                      Our team has spent a significant amount of time formulating our very own fabric softener that works
                      just as well as the products you buy in the store, and is made using only ingredients that have an EWG Score of 1 or 2 (Low Hazard). All of our premium customers have the option of adding
                      this unique product to their laundry.
                      <p>
                        <em>Ingredients: Water, <a href="https://www.ewg.org/skindeep/ingredient/701385/CITRIC_ACID/">Citric Acid</a>, <a href="https://www.ewg.org/skindeep/ingredient/706851/VEGETABLE_GLYCERIN/">Vegetable Glycerin</a>, <a href="https://www.ewg.org/skindeep/ingredient/701236/CETEARYL_ALCOHOL/">Cetearyl Alcohol</a>, <a href="https://www.ewg.org/skindeep/ingredient/700658/BEHENTRIMONIUM_METHOSULFATE/">Behentrimonium Methosulfate</a></em>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->


                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingThree">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <h5 class="mb-0">
                        My laundry was missed will I still get charged? <i class="fa fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <!-- Card body -->
                  <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                    <div class="card-body">
                      Our system only charges your account when we have a record of both a pick-up and a delivery. But take into consideration that we have a $5.95 fee for missed pickups.
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->


                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingFour">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <h5 class="mb-0">
                        How does In-Home Delivery work? <i class="fa fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <!-- Card body -->
                  <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                    <div class="card-body">
                      As a premium customer, with in home delivery we bring the laundry inside your house, even if you're not home. We do this by keeping a garage code
                      or by retrieving a key from a lockbox on your property. This service is recommended if you would like to have a secure place for us to drop off
                      the laundry.
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->



                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      <h5 class="mb-0">
                        Does FairMeadow have a setting for air dry? <i class="fa fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <!-- Card body -->
                  <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingSix" data-parent="#accordionEx">
                    <div class="card-body">
                      Due to our next day delivery, the time does not permit for air dry. Customers that have multiple items that they like to air dry can ask us to leave an entire
                      bin without drying. This will be returned damp in its bin. We can only do this for an entire bin, we will not do any sorting or separation of laundry. We must be notified ahead of time to make sure 
                      your bin is labeled properly and that it does not go through the drier.
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->

                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      <h5 class="mb-0">
                        How can I add another bin? <i class="fa fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <!-- Card body -->
                  <div id="collapseSeven" class="collapse" role="tabpanel" aria-labelledby="headingSeven" data-parent="#accordionEx">
                    <div class="card-body">
                      To add another bin simply put your extra laundry in a 13-gallon bag clearly labeled with the name on the account and set it on top of your bin on your assigned laundry day. Our drivers will know to take it, our team will process it and return it in a bin. If this was only a one-time use, please return the empty bin on your next pickup.
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->


                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                      <h5 class="mb-0">
                        I live in an apartment building. Would you be able to service me? <i class="fa fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <!-- Card body -->
                  <div id="collapseEight" class="collapse" role="tabpanel" aria-labelledby="headingEight" data-parent="#accordionEx">
                    <div class="card-body">
                      Of course! We will need access to your building whether it is through a dial box or a key secured in a lockbox that you would need to set up on your property.
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->


                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                      <h5 class="mb-0">
                        Do we separate lights and darks by bin or do you guys separate? <i class="fa fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <!-- Card body -->
                  <div id="collapseNine" class="collapse" role="tabpanel" aria-labelledby="headingNine" data-parent="#accordionEx">
                    <div class="card-body">
                      We encourage our customers to do all the sorting and separating at home into different bins. Due to our quick turn around our team is not able to do any sorting at our facility. The bins will always be treated as a whole and will be washed and dried together.
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->


                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingOne">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                      <h5 class="mb-0">
                        Do you guys stain treat? <i class="fa fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                  <!-- Card body -->
                  <div id="collapseTen" class="collapse" role="tabpanel" aria-labelledby="headingTen" data-parent="#accordionEx">
                    <div class="card-body">
                      We encourage our customers to stain treat at home. We do not do any stain treatments at our facility. We have had incidents where customers leave foreign
                      items in their bins like crayons, pens, chap stick, gum etc. And this has caused entire bins to become stained. We will do our best to help you with
                      ideas to stain treat but do not make our company responsible for damaged items.
                    </div>
                  </div>
                </div>
                <!-- Accordion card -->


                <!-- Accordion card -->
                <div class="card">

                  <!-- Card header -->
                  <div class="card-header" role="tab" id="headingFive">
                    <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <h5 class="mb-0">
                        I have a question that is not on the list, can you answer it? <i class="fa fa-angle-down rotate-icon"></i>
                      </h5>
                    </a>
                  </div>

                <!-- Card body -->
                <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                    <div class="card-body">
                      <p>
                        Absolutely! Please reach out at support@fairmeadow.app. Take into consideration that our emails are checked once a day during business hours. If this is a time-sensitive matter call or text us at (720)432-0168.
                      </p>
                      </div>
                    </div>
                  </div>
                <!-- Accordion card -->
                
                <div class="card">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>